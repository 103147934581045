import React, { Component } from "react";
import { Link } from "react-router-dom";
import ImageLazyLoad from "../section-components/ImageLazyLoad";
import parse from "html-react-parser";

class Ads extends Component {
	render() {
		const { imagejpeg, imagewebp } = this.props.data;
		return (
			<div className="ads-area pd-top-90 viaje-go-top">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-xl-8 col-lg-9">
							<Link className="ads-thumb" to="/contact">
								<ImageLazyLoad imagejpeg={imagejpeg} imagewebp={imagewebp} />
							</Link>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Ads;
